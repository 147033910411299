import { createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  DollarType,
  ExpenseTypes,
  BudgetID,
  progressReportLineItem,
  yearlyUpdateId,
  finalReportLineItem,
  stateSliceTypes,
  iSharedBudgetStates,
} from "../../../app/models";
import { igamUrl } from "../../../app/constants";
import { RootState } from "../../../app/store";
import { budgetTypes } from "../../../app/budgetTypes";
import { ProgramValues } from "../program-area-info/ProgramAreaValues";
import { 
  transformBudgetlineToProgressReportLine, 
  transformBudgetlineToFinalReportLine,  
} from "../helpers"

import {
  selectApplicablePaymentsAmountById,
  selectAllPaymentsAmount
} from './bbgDataSlice'
import { setRequirementSuccessMsg } from "./appStateSlice";

const { projected,  nextReport} = yearlyUpdateId

const newProgressReportLineItem: progressReportLineItem = {
  lineName: "",
    reqdLine: false,
    locked: false,
      budgets: {
    actual: { TDE: 0, Other: 0 },
    projected: { TDE: 0, Other: 0 },
    nextReport: { TDE: 0, Other: 0 }
  }
}
const newFinalReportLineItem: finalReportLineItem = {
  lineName: "",
    reqdLine: false,
    locked: false,
    finalBudget: { TDE: 0, Other: 0 }
}

const initialState: iSharedBudgetStates = {
  id: "",
  budgetKey: "",
  budgetLoading: "idle",
  saving: "idle",
  hasProgramExpenses: true,
  hasIndirectExpenses: false,
  budgetType: budgetTypes.HC1, //in ApplicationConnector, budgetType is retrieved from IGAM, so this budgetType is set to HC1 temporarily
  currentProgressReport: null,
  budgetItems: {
    revenue: [{ lineName: "The Duke Endowment", reqdLine: true, toolTip: "Your request from The Duke Endowment", TDEOnly: true, applicantContribution: false, years: {}},], 
    programExpense: [], 
    capitalExpense: [],
    indirectExpense: [] 
  },
  budgetNarrative: "",
  progressReports: [],
  finalReport: 0,
  progressReportBudgets: {}
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.


export const fetchBudgetIdAsync = createAsyncThunk(
  "budgetState/fetchBudgetIdAsync",
  async (props: {id: string, admin: boolean, budgetKey: string}) => {
    const {id, admin, budgetKey} = props;
    const response = await fetch(`/api/budget/${id}`, {
      method: 'GET',
      headers: {
        "budgetKey": budgetKey,
        "admin": admin ? "true":"false"
      }
    });
    // The value we return becomes the `fulfilled` action payload
    switch(response.status){
      case 404: return {loading: "new", budgetKey: budgetKey};
        break;
      case 401: return {loading: "failed", budgetKey: ""};
        break;
      case 200: return response.json();
        break;
      default: return {loading: "failed", budgetKey: ""};
    }
  }
);

export const fetchBudgetIdAsyncAdmin = createAsyncThunk(
  "budgetState/fetchBudgetIdAsync",
  async (id: string, { dispatch }) => {
    const response = await fetch(`/api/budget/admin/${id}`);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 404) {
      dispatch(setNotFound(true));
      return {};
    } else {
      dispatch(setNotFound(false));
    }
    return response.json();
  }
);

export const upsertBudgetIdAsync = createAsyncThunk(
  "budgetState/upsertBudgetIdAsync",
  async (
    { done }: { done: boolean },
    { getState, dispatch }
  ) => {
    const { budgetState } = getState() as {
      budgetState: iSharedBudgetStates;
    };
    const id = budgetState.id
    console.log(id)
    const response = await fetch(`/api/budget/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        projectTitle: budgetState.projectTitle,
        totalYears: budgetState.totalYears,
        budgetItems: budgetState.budgetItems,
        budgetType: budgetState.budgetType,
        hasProgramExpenses: budgetState.hasProgramExpenses,
        hasIndirectExpenses: budgetState.hasIndirectExpenses,
        state: "Progress Reports",
        approval: "pending",
        budgetKey: budgetState.budgetKey, 
        budgetNarrative: budgetState.budgetNarrative,
        progressReports: budgetState.progressReports,
        progressReportBudgets: budgetState.progressReportBudgets,
        finalReportBudget: budgetState.finalReportBudget,
        finalReport: budgetState.finalReport
      }),
    });
    if (response.ok && done) {

      dispatch(addToApplication(done));
      dispatch(setRequirementSuccessMsg(true))
      return ({done, response: response.text()})
    }
    return ({done: false, response: response.text()});
  }
);


export const budgetStateSlice = createSlice({
  name: "budgetState",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addToApplication: (state, action: PayloadAction<boolean>) => {
      const eventData = {
        key: "tde_budget_application_status",
        value: action.payload ? "Added" : "",
      };
      if (window.opener && action.payload) {
        window.opener.postMessage(eventData, igamUrl);
        console.log("window.open and done")
      } else {
        console.log(JSON.stringify(eventData));
      }
    },
    setNotFound: (state, action: PayloadAction<boolean>) => {
      state.notFound = action.payload;
    },
    updateProjectData: (state, action: PayloadAction<{projectTitle: string, totalYears: string }>) => {
      const payloadYears = Number(action.payload.totalYears);
      const payloadTitle = action.payload.projectTitle;
      if ( state.yearsIGAM !== payloadYears || state.projectTitle !== payloadTitle){
        state.yearsIGAM = Number(action.payload.totalYears);
        state.projectTitle = action.payload.projectTitle;
      }
    },
    setProjectTitle: (state, action: PayloadAction<string>) => {
      state.projectTitle = action.payload;
    },
    setTotalYears: (state, action: PayloadAction<number>) => {
      state.totalYears = action.payload;
    },
    setBudgetId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setBudgetKey: (state, action: PayloadAction<string>) => {
      state.budgetKey = action.payload;
    },
    addBudgetItemsLine: (state, action: PayloadAction<{category:string}>) =>{
      state.budgetItems[action.payload.category] = [...state.budgetItems[action.payload.category], { lineName: "", applicantContribution: false, years: {} } ]
    },
    removeBudgetItemsForYear: (state, action: PayloadAction<{category:string, index: number}>) => {
      state.budgetItems[action.payload.category] = state.budgetItems[action.payload.category].filter(
        (_, i) => i !== action.payload.index
      );
    },
    addNewProgressReport: (state) => {
      const reportKeys = Object.keys(state.progressReportBudgets);
      const lastProgressReport = state.progressReports[state.progressReports.length-1]
      reportKeys.length === 0
        ? state.progressReportBudgets = {
          ...state.progressReportBudgets,
          [state.currentProgressReport]: {
            reportingPeriodNarrative: "",
            actualEndDate: "",
            [BudgetID.revenue]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.revenue]),
            [BudgetID.programExpense]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.programExpense]),
            [BudgetID.indirectExpense]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.indirectExpense])
            }
          }
        : state.progressReportBudgets = {
          ...state.progressReportBudgets,
          [state.currentProgressReport]: {
            reportingPeriodNarrative: "",
            actualEndDate: "",
            [BudgetID.revenue]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.revenue]),
            [BudgetID.programExpense]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.programExpense]),
            [BudgetID.indirectExpense]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.indirectExpense])
          }
        }
      state.progressReports = [...state.progressReports, state.currentProgressReport];
    },
    addNewProgressReport_migration: (state, action: PayloadAction<{prId:number, actualEndDate}>) => {
      const reportKeys = Object.keys(state.progressReportBudgets);
      const lastProgressReport = state.progressReports[state.progressReports.length-1]
      reportKeys.length === 0
        ? state.progressReportBudgets = {
          ...state.progressReportBudgets,
          [state.currentProgressReport]: {
            reportingPeriodNarrative: "",
            actualEndDate: action.payload.actualEndDate,
            [BudgetID.revenue]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.revenue]),
            [BudgetID.programExpense]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.programExpense]),
            [BudgetID.indirectExpense]: transformBudgetlineToProgressReportLine(state.budgetItems[BudgetID.indirectExpense])
            }
          }
        : state.progressReportBudgets = {
          ...state.progressReportBudgets,
          [state.currentProgressReport]: {
            reportingPeriodNarrative: "",
            actualEndDate: action.payload.actualEndDate,
            [BudgetID.revenue]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.revenue]),
            [BudgetID.programExpense]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.programExpense]),
            [BudgetID.indirectExpense]: transformBudgetlineToProgressReportLine(state.progressReportBudgets[lastProgressReport][BudgetID.indirectExpense])
          }
        }
      state.progressReports = [...state.progressReports, action.payload.prId];
    },
    migrateBudgetInfo: (state, action: PayloadAction<iSharedBudgetStates>) => {
      state.id = action.payload.id;
      state.budgetKey = action.payload.budgetKey;
      state.budgetItems = action.payload.budgetItems || {};
      state.progressReports = action.payload.progressReports || [];
      state.progressReportBudgets = action.payload.progressReportBudgets || {};
      state.projectTitle = action.payload.projectTitle || "";
      state.totalYears = action.payload.totalYears;
      state.yearsIGAM = action.payload.yearsIGAM;
    },
    migrateUpdateProgressReport: (state, action: PayloadAction<{originalId:number, newId: number}>) => {
      // update array
      const {originalId, newId} = action.payload
      const newPrArray = [...state.progressReports];
      const indexOfProgressReport = state.progressReports.indexOf(originalId);
      newPrArray[indexOfProgressReport] = newId
      state.progressReports = newPrArray;

      //update budgets

      const newProgressReportsBudgets = {} //Object.create(state.progressReportBudgets);
      Object.keys(state.progressReportBudgets).forEach( key => {
        if( key === originalId.toString() ){
         newProgressReportsBudgets[newId] = state.progressReportBudgets[key]
        }else{
          newProgressReportsBudgets[key] = state.progressReportBudgets[key]
        }        
      })
      state.progressReportBudgets = newProgressReportsBudgets
      
    },
    migrateRemoveProgressReport: (state, action: PayloadAction<number>) => {
      const newPrArray = state.progressReports.filter( pr => pr !== action.payload)
      state.progressReports = newPrArray;

      const newProgressReportsBudgets = {} //Object.create(state.progressReportBudgets);
      Object.keys(state.progressReportBudgets).forEach( key => {
        if( key !== action.payload.toString() ){
         newProgressReportsBudgets[key] = state.progressReportBudgets[key]
        }        
      })
      state.progressReportBudgets = newProgressReportsBudgets
    },
    setBudgetNarrative: (state, action: PayloadAction<string>) => {
      state.budgetNarrative = action.payload;
    },
    setNewApplicationInfo: (
      state, 
      action: PayloadAction<{id: string, projectTitle: string, totalYears: string, budgetType: string, budgetKey: string,}>
      ) => {
      const {expensesSupport, indirectSupport } = ProgramValues(action.payload.budgetType);
      
      state.budgetKey = action.payload.budgetKey;
      state.projectTitle = action.payload.projectTitle;
      state.totalYears = Number(action.payload.totalYears);
      state.yearsIGAM = Number(action.payload.totalYears);
      state.id = action.payload.id
      state.budgetType = budgetTypes[action.payload.budgetType];
      state.hasProgramExpenses = expensesSupport;
      state.hasIndirectExpenses = indirectSupport;
      state.budgetLoading = "loaded"
    },
    addNewFinalReport: (state, action: PayloadAction<number>) => {
      if (state.progressReports.length === 0) {
        state.finalReportBudget = {
          [BudgetID.revenue]: transformBudgetlineToFinalReportLine(state.budgetItems[BudgetID.revenue]),
          [BudgetID.programExpense]: transformBudgetlineToFinalReportLine(state.budgetItems[BudgetID.programExpense]),
          [BudgetID.indirectExpense]: transformBudgetlineToFinalReportLine(state.budgetItems[BudgetID.indirectExpense])
        }
      }  else {
        const lastProgressReportId = state.progressReports[state.progressReports.length-1]
        const lastProgressReport = state.progressReportBudgets[lastProgressReportId]
        state.finalReportBudget = {
          [BudgetID.revenue]: transformBudgetlineToFinalReportLine(lastProgressReport[BudgetID.revenue]),
          [BudgetID.programExpense]: transformBudgetlineToFinalReportLine(lastProgressReport[BudgetID.programExpense]),
          [BudgetID.indirectExpense]: transformBudgetlineToFinalReportLine(lastProgressReport[BudgetID.indirectExpense])
        }
      }
      state.finalReport = action.payload
    },

    setCurrentRequirement: (state, action: PayloadAction<number>) => {
      state.currentProgressReport = action.payload
    },
    setReportingPeriodNarrative: (state, action: PayloadAction<{narrative: string}>) => {
      const {narrative} = action.payload

      state.progressReportBudgets[state.currentProgressReport].reportingPeriodNarrative = narrative;
    },
    setActualDate: (state, action: PayloadAction<{dateString: string}>) =>{
      const {dateString} = action.payload;
      const {currentProgressReport} = state;
      state.progressReportBudgets[currentProgressReport].actualEndDate = dateString;
    },
    setSelectedActualDate: (state, action: PayloadAction<{dateString: string, progressReportId}>) =>{
      const {dateString, progressReportId} = action.payload;
      state.progressReportBudgets[progressReportId].actualEndDate = dateString;
    },
    setBudgetItemsForYear: (
      state,
      action: PayloadAction<{ index: number; category: string; year: number; amount: number, typeDollars: string }>
    ) => {
      const {index, category, year, amount, typeDollars} = action.payload;
      state.budgetItems[category][index].years[year] = {...(state.budgetItems[category][index].years?.[year] || {} ),[typeDollars]: amount}
    },
    setProgressReportItemValue: (
      state,
      action: PayloadAction<{ progressReportId, category, index: number; budgetType: string; amount: number, typeDollars: string }>
    ) => {
      const {progressReportId, category, index, budgetType, amount, typeDollars} = action.payload;
      state.progressReportBudgets[progressReportId][category][index].budgets[budgetType][typeDollars] = amount
    },
    setBudgetType: (state, action: PayloadAction<budgetTypes>) =>{
      state.budgetType = action.payload
    },
    setFinalItemValue: (
      state,
      action: PayloadAction<{ category, index: number; amount: number, typeDollars: string }>
    ) => {
      const {category, index, amount, typeDollars} = action.payload;
      state.finalReportBudget[category][index].finalBudget[typeDollars] = amount
    },
    setRowName: (state, action: PayloadAction<{category: string, index: number, lineName: string}>) =>{
      state.budgetItems[action.payload.category][action.payload.index]['lineName'] = action.payload.lineName
    },
    setProgressReportRowName: (state, action: PayloadAction<{progressReportId: number, category: string, lineIndex: number, lineName: string}>) =>{
      const {progressReportId, lineIndex, category, lineName} = action.payload;
      state.progressReportBudgets[progressReportId][category][lineIndex].lineName = lineName
    },
    setFinalReportRowName: (state, action: PayloadAction<{ category: string, lineIndex: number, lineName: string}>) =>{
      const { lineIndex, category, lineName} = action.payload;
      state.finalReportBudget[category][lineIndex].lineName = lineName
    },
    addProgressItemsLine: (state, action: PayloadAction<{progressReportId: number, category:string}>) =>{
      const {progressReportId, category} = action.payload;
      state.progressReportBudgets[progressReportId][category] = [...state.progressReportBudgets[progressReportId][category], newProgressReportLineItem]
    },
    addFinalReportBudgetItemsLine: (state, action: PayloadAction<{ category:string}>) =>{
      const {category} = action.payload;
      state.finalReportBudget[category] = [...state.finalReportBudget[category], newFinalReportLineItem]
    },
    removeProgressReportItemsForYear: (state, action: PayloadAction<{progressReportId: number, category:string, lineIndex: number}>) => {
      const {progressReportId, category, lineIndex} = action.payload
      // state.requirementBudgets["1"].updatedFigures[action.payload.category] = state.requirementBudgets["1"].updatedFigures[action.payload.category].filter(
      //   (_, i) => i !== action.payload.index
      // );
      state.progressReportBudgets[progressReportId][category] = state.progressReportBudgets[progressReportId][category].filter( (_, i) => i!== action.payload.lineIndex )
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgetIdAsync.pending, (state) => {
        state.budgetLoading = "loading";
      })
      .addCase(fetchBudgetIdAsync.rejected, (state) => {
        state.budgetLoading = "failed";
      })
      .addCase(fetchBudgetIdAsync.fulfilled, (state, action) => {
        state.id = action.payload?.id;
        state.budgetKey = action.payload?.budgetKey;
        state.budgetLoading = action.payload?.loading || "loaded";
        state.totalYears = action.payload?.totalYears;
        state.budgetNarrative = action.payload?.budgetNarrative || "";
        state.budgetItems = action.payload?.budgetItems !== undefined 
            ? action.payload.budgetItems
            : initialState.budgetItems
        state.hasProgramExpenses =
          action.payload?.hasProgramExpenses !== undefined
            ? action.payload?.hasProgramExpenses
            : initialState.hasProgramExpenses;
        state.hasIndirectExpenses =
          action.payload?.hasIndirectExpenses !== undefined
            ? action.payload?.hasIndirectExpenses
            : initialState.hasIndirectExpenses;
        state.progressReports = 
          action.payload?.progressReports || [];
        state.finalReport = 
          action.payload?.finalReport || null;
        state.progressReportBudgets = 
          action.payload?.progressReportBudgets || {};
        state.finalReportBudget = 
          action.payload?.finalReportBudget;
      })

      .addCase(upsertBudgetIdAsync.pending, (state) => {
        state.saving = "saving";
      })
      .addCase(upsertBudgetIdAsync.rejected, (state) => {
        state.saving = "failed";
      })
      .addCase(upsertBudgetIdAsync.fulfilled, (state, action) => {
        state.saving = "saved";
      });
  },
});

export const {
  addToApplication,
  addNewProgressReport,
  addNewProgressReport_migration,
  addNewFinalReport,
  addBudgetItemsLine,
  setNewApplicationInfo,
  migrateBudgetInfo,
  migrateUpdateProgressReport,
  migrateRemoveProgressReport,
  updateProjectData,
  setBudgetNarrative,
  setProjectTitle,
  setTotalYears,
  setBudgetType,
  setBudgetId,
  setBudgetKey,
  setCurrentRequirement,
  setBudgetItemsForYear,
  setProgressReportItemValue,
  setFinalItemValue,
  setProgressReportRowName,
  setRowName,
  setFinalReportRowName,
  setNotFound,
  addProgressItemsLine,
  addFinalReportBudgetItemsLine,
  removeBudgetItemsForYear,
  removeProgressReportItemsForYear,
  setReportingPeriodNarrative,
  setActualDate,
  setSelectedActualDate
} = budgetStateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

const { BUDGETSTATE } = stateSliceTypes
const yearArray = (state: RootState) => Array.apply(null, Array(state[BUDGETSTATE].totalYears)).map((x, index) => index + 1);

                  /////// COMMON REPORT SELECTORS /////////

export const selectBudgetType = (state: RootState) =>
  state[BUDGETSTATE].budgetType

export const selectExpenseTypesAvailable = (state: RootState) =>
({
  hasProgramExpenses: state[BUDGETSTATE].hasProgramExpenses,
  hasIndirectExpenses: state[BUDGETSTATE].hasIndirectExpenses
})

export const selectBudgetLoading = (state: RootState) => 
  state[BUDGETSTATE].budgetLoading
export const selectBudgetId = (state: RootState) => 
  state[BUDGETSTATE].id
export const selectBudgetKey = (state: RootState) => 
  state[BUDGETSTATE].budgetKey
export const selectProgressReports = (state: RootState ) =>
  state[BUDGETSTATE].progressReports;
export const selectProgressReportBudgets = (state: RootState ) =>
  state[BUDGETSTATE].progressReportBudgets;
export const selectTotalYears = (state: RootState) =>
  state[BUDGETSTATE].totalYears;
export const selectYearsIGAM = (state: RootState) =>
  (state[BUDGETSTATE].yearsIGAM || 3);
export const selectHasProgramExpenses = (state: RootState) =>
  state[BUDGETSTATE].hasProgramExpenses;
export const selectSavingStatus = (state: RootState) =>
  state[BUDGETSTATE].saving;
export const selectCurrentProgressReport = (state: RootState) =>
  state[BUDGETSTATE].currentProgressReport
export const selectIsFirstProgressReport: (
  state: RootState
) => ((progressReportId: number) => boolean) = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BUDGETSTATE].progressReports[0] === progressReportId
  }
}

export const selectVisibleProgressReports = (state: RootState) => {
  const {currentProgressReport, progressReports} = state[BUDGETSTATE]
  const currentIndex = progressReports.indexOf(currentProgressReport)
  return (progressReports.slice(0, currentIndex + 1)) 
}
export const selectReportingPeriodNarrative: (
  state: RootState
) => ((progressReportId: number) => string ) = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BUDGETSTATE].progressReportBudgets?.[progressReportId]?.reportingPeriodNarrative || "";
  }
  }
export const selectActualEndDate: (
  state: RootState
) => ((progressReportId: number) => string) = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BUDGETSTATE].progressReportBudgets?.[progressReportId]?.actualEndDate || "";
  }
}

  /////// PROGRESS REPORT SELECTORS /////////


export const selectProgressReportKeys = (state: RootState) =>
  state[BUDGETSTATE].progressReports

export const selectProgressReportItems: (
  state: RootState
) => ((progressReportId: number) => {}) = (state: RootState) => {
  return (progressReportId: number) => {
    return state[BUDGETSTATE].progressReportBudgets[progressReportId]
  }
};

export const selectProgressReportItemValue: (
  state: RootState
) => ((progressReportId: number, category: any, lineIndex: number, yearlyUpdateId: string, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, category: any, lineIndex: number, budgetType: string, typeDollars: string) => {
    return (state[BUDGETSTATE].progressReportBudgets[progressReportId][category][lineIndex]?.budgets[budgetType]?.[typeDollars] || 0)
  }
};

export const selectProgressReportItemName: (
  state: RootState
) => ((progressReportId: number, category: any, lineIndex: number) => number) = (state: RootState) => {
  return (progressReportId: number, category: any, lineIndex: number) => {
    return (state[BUDGETSTATE].progressReportBudgets?.[progressReportId][category][lineIndex]?.lineName || "")
  }
};

export const selectProgressReportItemsCategorySubTotal: (
  state: RootState
) => ((progressReportId: number, category: string, budgetType: string, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, category: string, budgetType: string, typeDollars: string) => {
    return state[BUDGETSTATE].progressReportBudgets?.[progressReportId]?.[category].reduce((acc, nextVal) => {
      return acc + (nextVal.budgets[budgetType]?.[typeDollars] || 0)
    }, 0)
  };
};

export const selectProgressReportItemsCategoryTotal: (
  state: RootState
) => ((progressReportId: number, category: string, budgetType: string) => number) = (state: RootState) => {
  return (progressReportId: number, category: string, budgetType: string) => {
    return selectProgressReportItemsCategorySubTotal(state)(progressReportId, category, budgetType, DollarType.TDE) + 
            selectProgressReportItemsCategorySubTotal(state)(progressReportId, category, budgetType, DollarType.Other)
  }
};


export const selectPreviousProgressReportId: (
  state: RootState
) => ((progressReportId: number) => number) = (state: RootState) => {
  return (progressReportId: number) => {
      const {progressReports} = state[BUDGETSTATE];
      const currentProgressReportLoc = progressReports.indexOf(progressReportId);
      if (currentProgressReportLoc === 0){
        return 0
      }
      const previousProgressReportId = progressReports[currentProgressReportLoc - 1]
      return previousProgressReportId
  }
};

export const selectProgressReportItemsRevenueSubTotal2: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number,  budgetType: yearlyUpdateId, typeDollars: string) => {
    const isFirstProgressReport = selectIsFirstProgressReport(state)(progressReportId);
    const subTotal = selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.revenue, budgetType, typeDollars)
    const payments = selectApplicablePaymentsAmountById(state)(progressReportId);
    const paymentAmount = (typeDollars === DollarType.TDE) ? payments[budgetType] : 0
    if (isFirstProgressReport){
      if(budgetType === yearlyUpdateId.current){
        return selectCurrentBudgetCategorySubTotal(state)(progressReportId, BudgetID.revenue, budgetType, typeDollars)
      }else if(budgetType === yearlyUpdateId.nextReport){
        const thisYearProjectedNet = selectProgressReportItemsCategoryNetSubTotal2(state)(progressReportId, yearlyUpdateId.projected, typeDollars)
        return subTotal + paymentAmount + thisYearProjectedNet
      }else{
        return subTotal + paymentAmount
      }

    }
    const previousProgressReportId = selectPreviousProgressReportId(state)(progressReportId)
    const carryForward = selectProgressReportCarryForwardValue(state)(previousProgressReportId, typeDollars)
    return subTotal +
            paymentAmount +
            carryForward
            //TDERevenueInput TODO
  };
};

export const selectProgressReportItemsExpenseSubTotal: (
  state: RootState
) => ((progressReportId: number, budgetCategory: BudgetID, budgetType: yearlyUpdateId,  typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, budgetCategory: BudgetID,  budgetType: yearlyUpdateId, typeDollars: string) => {
    const isFirstProgressReport = selectIsFirstProgressReport(state)(progressReportId)

    if (isFirstProgressReport && budgetType === yearlyUpdateId.current){
      return selectCurrentBudgetCategorySubTotal(state)(progressReportId, budgetCategory, budgetType, typeDollars)
    }

    return selectProgressReportItemsCategorySubTotal(state)(progressReportId, budgetCategory, budgetType, typeDollars)
  };
};

export const selectProgressReportItemsCategoryNetSubTotal2: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => {
    return (
      selectProgressReportItemsRevenueSubTotal2(state)(progressReportId, budgetType, typeDollars) - 
      selectProgressReportItemsExpenseSubTotal(state)(progressReportId, BudgetID.indirectExpense, budgetType, typeDollars) -
      selectProgressReportItemsExpenseSubTotal(state)(progressReportId, BudgetID.programExpense, budgetType, typeDollars)
      // selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.indirectExpense, budgetType, typeDollars)  -
      // selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.programExpense, budgetType, typeDollars) 
    )

  }
};

export const selectProgressReportItemsCategoryNetTotal2: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId) => {
    return (
      selectProgressReportItemsCategoryNetSubTotal2(state)(progressReportId, budgetType, DollarType.TDE) + 
      selectProgressReportItemsCategoryNetSubTotal2(state)(progressReportId, budgetType, DollarType.Other)
    )

  }
};

export const selectProgressReportItemsRevenueSubTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number,  budgetType: yearlyUpdateId, typeDollars: string) => {
    return selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.revenue, budgetType, typeDollars) +
            selectProgressReportCarryForwardValue(state)(progressReportId, typeDollars) //+
            //TDERevenueInput TODO
  };
};

export const selectProgressReportItemsCategoryNetSubTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: string, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: string, typeDollars: string) => {
    return (
      selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.revenue, budgetType, typeDollars) - 
      selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.indirectExpense, budgetType, typeDollars)  -
      selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.programExpense, budgetType, typeDollars) 
    )

  }
};

export const selectProgressReportItemsCategoryNetTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: string) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: string) => {
    return (
      selectProgressReportItemsCategoryNetSubTotal(state)(progressReportId, budgetType, DollarType.TDE)  +
      selectProgressReportItemsCategoryNetSubTotal(state)(progressReportId, budgetType, DollarType.Other)
    )

  }
};

export const selectProgressReportCarryForwardValue: (
  state: RootState
) => ((progressReportId: number, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, typeDollars: string) => {


    return selectProgressReportItemsCategoryNetSubTotal2(state)(progressReportId, projected, typeDollars) || 0
  }
};


export const selectProgressReportItemsRevenueTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId) => {
    return selectProgressReportItemsRevenueSubTotal(state)(progressReportId, budgetType, DollarType.TDE) +
          selectProgressReportItemsRevenueSubTotal(state)(progressReportId, budgetType, DollarType.Other)
  }
};

export const selectProgressReportItemsRevenueTotal2: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId) => {
    return selectProgressReportItemsRevenueSubTotal2(state)(progressReportId, budgetType, DollarType.TDE) +
          selectProgressReportItemsRevenueSubTotal2(state)(progressReportId, budgetType, DollarType.Other)
  }
};

export const selectProgressReportItemsExpensesSubTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId, typeDollars:string) => number) = (state: RootState) => {
  return (progressReportId: number,  budgetType: yearlyUpdateId, typeDollars:string) => {
    return selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.programExpense, budgetType, typeDollars) +
      selectProgressReportItemsCategorySubTotal(state)(progressReportId, BudgetID.indirectExpense, budgetType, typeDollars)
  }
};

export const selectProgressReportItemsExpensesTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId) => number) = (state: RootState) => {
  return (progressReportId: number,  budgetType: yearlyUpdateId) => {
    return selectProgressReportItemsExpensesSubTotal(state)(progressReportId, budgetType, DollarType.TDE) +
            selectProgressReportItemsExpensesSubTotal(state)(progressReportId, budgetType, DollarType.Other)
  }
};

export const selectProgressReportItemsNetSubTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId, typeDollars: string) => {
    return selectProgressReportItemsRevenueSubTotal(state)(progressReportId, budgetType, typeDollars) -
      selectProgressReportItemsExpensesSubTotal(state)(progressReportId, budgetType, typeDollars)
  }
};

export const selectProgressReportItemsNetTotal: (
  state: RootState
) => ((progressReportId: number, budgetType: yearlyUpdateId) => number) = (state: RootState) => {
  return (progressReportId: number, budgetType: yearlyUpdateId) => {
    return selectProgressReportItemsNetSubTotal(state)(progressReportId, budgetType, DollarType.TDE) +
            selectProgressReportItemsNetSubTotal(state)(progressReportId, budgetType, DollarType.Other)
  }
};
                  /////// BUDGET SELECTORS /////////

export const selectProjectTitle = (state: RootState) =>
state[BUDGETSTATE].projectTitle;

export const selectBudgetItems = (state: RootState) =>
  state[BUDGETSTATE].budgetItems;

export const selectBudgetNarrative = (state: RootState) =>
  state[BUDGETSTATE].budgetNarrative;
export const selectCategoryDropDownOptions: (
  state: RootState
) => ((category: BudgetID) => string[]) = (state: RootState) => {
  return (category: BudgetID) => {
    const expenseTypes = ProgramValues(state[BUDGETSTATE].budgetType).expenseTypes
    return expenseTypes.filter( expense => expense.type == category).map(type => type.Name)
  };
};
export const selectBudgetItemYear: (
  state: RootState
) => ((category: string, index: number, year: number, typeDollars: string) => number) = (state: RootState) => {
  return (category: string, index: number, year: number, typeDollars: string) => {
    return (state[BUDGETSTATE].budgetItems[category]?.[index]?.years?.[year]?.[typeDollars] || 0)
  }
};

export const selectBudgetItemsCategorySubTotalYear: (
  state: RootState
) => ((year: number, category: string, typeDollars: string) => number) = (state: RootState) => {
  return (year: number, category: string, typeDollars: string) => {
    return state[BUDGETSTATE].budgetItems[category].reduce((accIndex, next) => {
      return accIndex + (next.years[year]?.[typeDollars] || 0);
    }, 0);
  };
};

export const selectBudgetItemsCategoryTotalYear: (
  state: RootState
) => ((year: number, category: string) => number) = (state: RootState) => {
  return (year: number, category: string) => {
    return selectBudgetItemsCategorySubTotalYear(state)(year, category, DollarType.TDE) + selectBudgetItemsCategorySubTotalYear(state)(year, category, DollarType.Other)
  }
};



export const selectBudgetItemsCategorySumYear: (
  state: RootState
) => ((year: number, category: string, typeDollars: string) => number) = (state: RootState) => {
  return (year: number, category: string, typeDollars: string) => {
    return state[BUDGETSTATE].budgetItems[category].reduce((accIndex, next) => {
      return accIndex + (next.years[year]?.[typeDollars] || 0);
    }, 0);
  };
};

export const selectRevenueBudgetItemsTotal: (
  state: RootState
) => (() => number) = (state: RootState) => {
  return () => {
    return Object.keys(state[BUDGETSTATE].budgetItems).filter(x => x == BudgetID.revenue).reduce((accCat, key) => {
      return accCat + state[BUDGETSTATE].budgetItems[key].reduce((accIndex, next) => {
        return accIndex + yearArray(state).reduce((accYears, year) => {
          return accYears + (next.years[year]?.[DollarType.dollars] || 0)
        }, 0)
      }, 0)
    }, 0);
  };
};

export const selectBudgetItemsCategorySubTotal: (
  state: RootState
) => ((category: string, dollarType: string) => number) = (state: RootState) => {
  return (category: string, dollarType: string) => {
    return state[BUDGETSTATE].budgetItems[category].reduce((accIndex, next) => {
      return accIndex + Object.keys(next.years).reduce((accYears, key) => {
        return accYears + (next.years[key]?.[dollarType] || 0)
      }, 0)
    }, 0);
  };
};

export const selectBudgetItemsCategoryTotal: (
  state: RootState
) => ((category: string) => number) = (state: RootState) => {
  return (category: string) => {
    return selectBudgetItemsCategorySubTotal(state)(category, DollarType.TDE) + selectBudgetItemsCategorySubTotal(state)(category, DollarType.Other)
  };
};



export const selectBudgetContributionsTotal: (
  state: RootState
) => (() => number) = (state: RootState) => {
  return () => {
    return (yearArray(state)
      .reduce((acc, next) => acc + (state[BUDGETSTATE].budgetItems[BudgetID.revenue][1].years[next]?.[DollarType.dollars] || 0), 0) +
      yearArray(state)
        .reduce((acc, next) => acc + (state[BUDGETSTATE].budgetItems[BudgetID.revenue][2].years[next]?.[DollarType.dollars] || 0), 0)
      );
  };
};

export const selectExpenseBudgetItemsSubTotalYear: (
  state: RootState
) => ((year: number, typeDollars: DollarType) => number) = (state: RootState) => {
  return (year: number, typeDollars: DollarType) => {
    return Object.keys(state[BUDGETSTATE].budgetItems).filter(x => x in ExpenseTypes)
      .reduce((acc, key) => acc + state[BUDGETSTATE].budgetItems[key]
        .reduce((accc, next) => accc + (next.years[year]?.[typeDollars] || 0), 0), 0);
  };
};

export const selectIndirectDirectExpenseRatioYear: (
  state: RootState
) => ((year: number) => number) = (state: RootState) => {
  return (year: number) => {
    return (
      (selectBudgetItemsCategoryTotalYear(state)(year, BudgetID.indirectExpense) / selectExpenseBudgetItemsSumYear(state)(year)) * 100
    )
  }
}

export const selectIndirectDirectExpenseRatio: (
  state: RootState
) => (() => number) = (state: RootState) => {
  return () => {
    //console.log(selectBudgetItemsCategoryTotal(state)(BudgetID.indirectExpense), selectExpenseBudgetItemsTotal(state)())
    return (
      (selectBudgetItemsCategoryTotal(state)(BudgetID.indirectExpense) / selectExpenseBudgetItemsTotal(state)()) * 100
    )
  }
}



// Sum of dollars and inKind expenses each year (works for just dollars as well)


export const selectExpenseBudgetItemsSumYear: (
  state: RootState
) => ((year: number) => number) = (state: RootState) => {
  return (year: number) => {
    return Object.keys(state[BUDGETSTATE].budgetItems).filter(x => x in ExpenseTypes)
      .reduce((acc, key) => acc + state[BUDGETSTATE].budgetItems[key]
        .reduce((accc, next) => accc + (next.years[year]?.[DollarType.TDE] || 0) + (next.years[year]?.[DollarType.Other] || 0), 0), 0);
  };
};

export const selectExpenseBudgetItemsSubTotal: (
  state: RootState
) => ((typeDollars: string) => number) = (state: RootState) => {
  return (typeDollars: string) => {
    return Object.keys(state[BUDGETSTATE].budgetItems).filter(x => x !== BudgetID.revenue).reduce((accCat, key) => {
        return accCat + state[BUDGETSTATE].budgetItems[key].reduce((accIndex, next) => {
          return accIndex + Object.keys(next.years).reduce( (accYears, year) => {
            return accYears + (next.years[year]?.[typeDollars] || 0)
          }, 0)
          },0) 
    },0);
  };
};
export const selectExpenseBudgetItemsTotal: (
  state: RootState
) => (() => number) = (state: RootState) => {
  return () => {
    return Object.keys(state[BUDGETSTATE].budgetItems).filter(x => x !== BudgetID.revenue).reduce((accCat, key) => {
      return accCat + state[BUDGETSTATE].budgetItems[key].reduce((accIndex, next) => {
        return accIndex + Object.keys(next.years).reduce((accYears, year) => {
          return accYears + (next.years[year]?.[DollarType.TDE] || 0) + (next.years[year]?.[DollarType.Other] || 0)
        }, 0)
      }, 0)
    }, 0);
  };
};

export const selectBudgetItemsSumTotal: (
  state: RootState
) => ((category: string) => number) = (state: RootState) => {
  return (category: string) => {
    return state[BUDGETSTATE].budgetItems[category].reduce((acc, next) => {
      return acc + Object.keys(next).filter(key => !["lineName", "reqdLine"].includes(key))
        .reduce((prev, key) => prev + (next[key]?.[DollarType.dollars] || 0), 0) 
    }, 0);
  };
};

// Sum of dollars expenses for currentBudget, actualYTD, projectedYearEnd, OR nextYear  (works for just dollars as well)
//TODO complete math
export const selectExpenseBudgetItemsSum: (
  state: RootState
) => ((yearlyUpdateId: string) => number) = (state: RootState) => {
  return (yearlyUpdateId: string) => {
    return 222
    //  return Object.keys(state[BUDGETSTATE].requirementBudgets["1"].updatedFigures).filter(x => x === 'programExpense')
    //   .reduce((acc, key) => state[BUDGETSTATE].requirementBudgets["1"].updatedFigures[key]
    //     .reduce((accc, next) => accc + (next[yearlyUpdateId]?.[DollarType.TDE] || 0) + (next[yearlyUpdateId]?.[DollarType.Other] || 0) , 0), 0);
  };
};


export const selectBudgetItemsExpensesSubTotal: (
  state: RootState
) => ((category: string, dollarType: string) => number) = (state: RootState) => {
  return (category: string, dollarType: string) => {
    return state[BUDGETSTATE].budgetItems[category].reduce((acc, next) => {
      return acc + Object.keys(next).filter(key => !["lineName", "reqdLine"].includes(key))
        .reduce( (prev, key) => prev + (next[key]?.[dollarType] || 0), 0)
    }, 0);
  };
};

export const selectBudgetItemsSubTotal: (
  state: RootState
) => ((category: string, dollarType: string, index: number) => number) = (state: RootState) => {
  return (category: string, dollarType: string, index: number) => {
    return Object.keys(state[BUDGETSTATE].budgetItems[category]?.[index]?.years || [])
      .reduce((acc, next) => acc + (state[BUDGETSTATE].budgetItems[category][index].years[next]?.[dollarType] || 0), 0);
  };
};


export const selectCurrentBudgetCategorySubTotal: (
  state: RootState
) => ((progressReport: number, category: string, budgetType: yearlyUpdateId, dollarType: string) => number) = (state: RootState) => {
  return (progressReport: number, category: string, budgetType: yearlyUpdateId, dollarType: string) => {
    const previousReportId = selectPreviousProgressReportId(state)(progressReport)
    const firstYear = 1
    const isFirstProgressReport = selectIsFirstProgressReport(state)(progressReport)
    return isFirstProgressReport
            ? selectBudgetItemsCategorySubTotalYear(state)(firstYear, category, dollarType)
            : selectProgressReportItemsRevenueSubTotal(state)(previousReportId, budgetType, dollarType)
  };
};

export const selectCurrentBudgetCategoryTotal: (
  state: RootState
) => ((progressReport: number, category: string) => number) = (state: RootState) => {
  return (progressReport: number, category: string) => {
    const previousReportId = selectPreviousProgressReportId(state)(progressReport)
    const firstYear = 1
    const isFirstProgressReport = selectIsFirstProgressReport(state)(progressReport)
    return isFirstProgressReport
            ? selectBudgetItemsCategoryTotalYear(state)(firstYear, category)
            : selectProgressReportItemsNetTotal(state)(previousReportId, nextReport)
  };
};
  

                  /////// Final Report Selectors /////////


export const selectFinalReportId = (state: RootState) =>
  state[BUDGETSTATE].finalReport

export const selectNotFound = (state: RootState) =>
  state[BUDGETSTATE].notFound;

export const selectFinalReportCategoryItems: (
  state: RootState
) => ((category: string) => []) = (state: RootState) => {
  return (category: string) => {
    return state[BUDGETSTATE].finalReportBudget[category]
  }
};
export const selectFinalReportItemValue: (
  state: RootState
) => ((category: any, lineIndex: number, typeDollars: string) => number) = (state: RootState) => {
  return (category: any, lineIndex: number,  typeDollars: string) => {
    return (state[BUDGETSTATE].finalReportBudget[category][lineIndex].finalBudget?.[typeDollars] || 0)
  }
};

export const selectFinalReportItemOverUnderValue: (
  state: RootState
) => ((category: any, lineIndex: number, typeDollars: string) => number) = (state: RootState) => {
  return (category: any, lineIndex: number,  typeDollars: string) => {
    const tdeRev = category === "revenue" && lineIndex === 0 && typeDollars === DollarType.TDE ? selectAllPaymentsAmount(state) : 0 //TODO selectAllPaymentsAmount(state) : 0;
    return (
      selectFinalReportItemValue(state)(category, lineIndex, typeDollars) + tdeRev -
      selectBudgetItemsSubTotal(state)(category, typeDollars, lineIndex)
      )
  }
};

export const selectFinalReportCategoryOverUnderValueSubTotal: (
  state: RootState
) => ((category: string, dollarType: string) => number) = (state: RootState) => {
  return (category: string, dollarType: string) => {
    return Object.keys(state[BUDGETSTATE].finalReportBudget[category])
      .reduce((acc, next) => acc + selectFinalReportItemOverUnderValue(state)(category, Number(next), dollarType), 0);
  };
};



export const selectFinalReportItemName: (
  state: RootState
) => ((category: any, lineIndex: number) => number) = (state: RootState) => {
  return (category: any, lineIndex: number) => {
    return (state[BUDGETSTATE]?.finalReportBudget?.[category][lineIndex].lineName)
  }
};

export const selectFinalReportCategorySubTotal: (
  state: RootState
) => ((category: string, dollarType: DollarType) => number) = (state: RootState) => {
  return (category: string, dollarType: DollarType) => {
    const tdeRev = category === "revenue" && dollarType === DollarType.TDE ? selectAllPaymentsAmount(state) : 0 //TODO selectAllPaymentsAmount(state) : 0;
    //console.log("FinalReportCategorySubTotal", category, dollarType, tdeRev)
    return Object.keys(state[BUDGETSTATE].finalReportBudget[category])
      .reduce((acc, next) => acc + (state[BUDGETSTATE].finalReportBudget[category][next].finalBudget?.[dollarType] || 0), 0) + tdeRev;
  };
};

export const selectFinalReportCategoryTotal: (
  state: RootState
) => ((category: string) => number) = (state: RootState) => {
  return (category: string) => {
    return selectFinalReportCategorySubTotal(state)(category, DollarType.TDE) + 
            selectFinalReportCategorySubTotal(state)(category, DollarType.Other)
  };
};


export default budgetStateSlice.reducer;

